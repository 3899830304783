import React from "react";
import "./SmallHeader.css";
import { Container } from "react-bootstrap";

const SmallHeaderText = ({ smalltext }) => {
  return (
    <div data-aos="fade-up">
      <Container>
        <p
          className="smalltxt mx-5">{smalltext}</p>
      </Container>
    </div>
  );
};

export default SmallHeaderText;
