import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Images from "../../utilties/Image";

const LifeSlider = () => {
  return (
    <div data-aos="zoom-in">
      <Carousel>
        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image1}
            alt="First slide"
            // height={200}
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image18}
            alt="eighteenth slide"
            // height={200}
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image2}
            alt="Second slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image12}
            alt="twelve slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image3}
            alt="Third slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image4}
            alt="Fourth slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image19}
            alt="nineteen slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image5}
            alt="Fifth slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image13}
            alt="thirteen slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image6}
            alt="sixth slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image20}
            alt="twenty slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image7}
            alt="seventh slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image14}
            alt="fourteen slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image8}
            alt="eight slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image21}
            alt="twenty one slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image9}
            alt="ninth slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image15}
            alt="fifteen slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image10}
            alt="tenth slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image11}
            alt="eleventh slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image16}
            alt="sixteen slide"
            width={1300}
          />
        </Carousel.Item>

        <Carousel.Item interval={2200} align="center">
          <img
            className="d-block img-fluid"
            src={Images.image17}
            alt="seventeen slide"
            width={1300}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default LifeSlider;
