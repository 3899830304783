import React from "react";
import Faq from "../../component/FAQ/Faq";
import CardData from "../../component/CardData";
import { Col, Container, Row } from "react-bootstrap";
import RecruitmentEvu from "../../component/RecruitmentEvolution/RecruitmentEvolution";
import RecuritmetCard from "../../component/RecruitmentEvolution/RecruitmetCard";
import Recruitmentpro from "../../component/RecruitmentProcess/RecruitmentProcess";
import Recruitmentcomp1 from "../../component/RecruitmentBanner/RecruitmentComp1";

function Recruitment() {
  return (
    <>
      <Recruitmentcomp1 />

      <Recruitmentpro />

      <RecruitmentEvu />
      <Container >
        <Row className="justify-content-md-center g-5">
          <Col>
            <RecuritmetCard
              cardheader={CardData.cardheader16}
              cardText={CardData.carddetail16}
            />
          </Col>

          <Col>
            <RecuritmetCard
              cardheader={CardData.cardheader17}
              cardText={CardData.carddetail17}
            />
          </Col>

          <Col>
            <RecuritmetCard
              cardheader={CardData.cardheader25}
              cardText={CardData.carddetail25}
            />
          </Col>
        </Row>
      </Container>

      <Faq />

    </>
  );
}

export default Recruitment;
