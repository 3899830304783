import React from "react";
import image from "./../utilties/Image";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


export default function LogoCompoment() {
  let navigate = useNavigate();

  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true });

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Link to="/"
        onClick={() => changeLocation('/')}>

        <img
          src={image.prydan}
          style={{ width: "160px", height: "60px", lineHeight: "0" }}
          alt="Prydan Logo">
        </img>
      </Link>
    </>
  );
}
