export const addUser = (value) => {
    return {
        type: "AddApplicant_Request",
        payload: value
    }
}

export const getpos = (value) => {
    return {
        type: "Position_Request",
        payload: value
    }
}