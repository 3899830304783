const Numberinfo = {
  number1: "20",
  detail1: "Web App Designed",

  number2: "50",
  detail2: "Mobile App Designed",

  number3: "10",
  detail3: "Software Designed",

  number4: "50",
  detail4: "Live Project"
};
export default Numberinfo;
