import axios from "axios";
import "./HomeFeedback.css";
import Error from "../Error";
import HeaderData from "../HeaderData";
import Images from "../../utilties/Image";
import HeaderText from "../HeadText/Header";
import Carousel from "better-react-carousel";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BASE_URL, FeedbackData } from "../../api";

const HomeFeedback = () => {
  const [feedbackdata, setfeedbackdata] = useState([]);
  const [isError, setError] = useState(false);

  const isEven = (n) => {
    return n % 2 === 0;
  };

  useEffect(() => {
    axios
      .get(BASE_URL + FeedbackData)
      .then((res) => {
        setfeedbackdata(res.data);
      })
      .catch((err) => {
        setError(true);
        throw err;
      });
  }, []);

  return (
    <div data-aos="fade-up">
      <Container>
        <HeaderText
          heading={HeaderData.feedback}
          smalltext={HeaderData.feedbackText}
        />
      </Container>

      {isError ?
        <Error />
        : null}
      <Container>
        <Carousel
          cols={2}
          rows={1}
          gap={10}
          autoplay={2000}
          arrowLeft
          arrowRight
          loop
        >
          {feedbackdata &&
            feedbackdata.map(
              ({ Fid, DeveloperName, Designation, Discription, Image }) => {
                return (
                  <Carousel.Item key={Discription} id={Discription}>
                    <Container
                      className={isEven(Fid) ? "feedback-bg2" : "feedback-bg1"}>
                      <Card.Body>
                        <Row className="content">
                          <img
                            src={Images.feedback}
                            alt="Feed"
                            className="invert"
                          />
                          <h5 className="feedback">{Discription}</h5>
                        </Row>

                        <Row>
                          <Col sm={1} className="my-3 f-img mx-4">
                            <Card.Img src={Image} className="photo" />
                          </Col>

                          <Col className="my-auto text-left mt-2" sm={8}>
                            <Card.Title className="content">
                              {DeveloperName}
                            </Card.Title>
                            <Card.Text className="designation ">
                              - {Designation}
                            </Card.Text>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Container>
                  </Carousel.Item>
                );
              }
            )}
        </Carousel>
      </Container>
    </div>
  );
};

export default HomeFeedback;
