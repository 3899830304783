export const BASE_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://103.1.100.53:4000/";

export const PositionData = "position/";
export const CandiateData = "candidate/";
export const FeedbackData = "feedback/";
export const BlogData = "blogs";
export const Blogid = "blog/";
export const CaseData = "case-study";
