import "./Blog.css";
import React from "react";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const BlogComponent = ({ title, img, content, bid }) => {
  const setId = (value) => {
    sessionStorage.setItem("id", value);
  };

  return (
    <Container className="blog-c">
      <Card className="blog-card my-5">
        <Card.Header
          className="blog-header blog-color mx-3 my-2">
          {title}
        </Card.Header>

        <Card.Img className="" src={img} />

        <Card.Body className="blog-body">
          <Row xs="auto">
            <Col sm={10}>
              <Card.Text
                className="blog-content">{content} […]</Card.Text>
            </Col>

            <Col>
              <Link to="/blog-content/">
                <Button
                  variant="info"
                  className="b-b p-3"
                  onClick={() => {
                    setId(bid);
                  }}
                >

                  <MdArrowForwardIos />

                </Button>
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default BlogComponent;
