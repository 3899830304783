import "./BannerCard.css";
import React from "react";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";

const BannerCard = ({ cardimg, cardheader, cardText, headcolor }) => {
  return (
    <Card className="h-card" data-aos="fade-up">
      <Row sx="auto">
        <Col className="card-first-col1 ms-3 ">
          <Card.Img src={cardimg} className="mt-4 rectangle-img" alt="card-image" />
        </Col>

        <Row sx="auto">
          <Col>
            <Card.Body>
              <Card.Title className="rectangle-title  ms-2"
                style={{ color: headcolor }}
              >{cardheader}</Card.Title>
            </Card.Body>
          </Col>
        </Row>
      </Row>

      <Row sx="auto">
        <Col className="ms-4 me-1 mb-1">
          <Card.Text className="rectangle-text"> {cardText}</Card.Text>
        </Col>
      </Row>
    </Card>
  );
};
export default BannerCard;