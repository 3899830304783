import { call, put, takeEvery } from "redux-saga/effects";
import AddApplicantRequest from "../request/AddApplicantRequest";

function* handleAddApplicant(payload) {
  try {
    const Applicant = yield call(AddApplicantRequest, payload);
    yield put({ type: "AddApplicant_Success", payload: Applicant });
  } catch (err) {
    yield put({ type: "AddApplicant_Failed", message: err.message });
  }
}

function* watchAddApplicants() {
  yield takeEvery("AddApplicant_Request", handleAddApplicant);
}

export default watchAddApplicants;
