import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL, CandiateData } from "../../../api";

function AddApplicantRequest(Values) {
  return axios
    .post(
      BASE_URL + CandiateData,
      {
        Current_Organization: Values.payload.CurrentOrganization,
        Total_Experience: Values.payload.TotalExperience,
        Relavant_Experience: Values.payload.RelevantExperience,
        Joining_Period: Values.payload.joiningPeriod,
        Current_AnnualPay: Values.payload.currentAnuualPay,
        Expected_AnnualPay: Values.payload.expectedAnuualPay,
        Position_AppliedFor: Values.payload.positionAppliedFor,
        Personal_Website: Values.payload.personalBlogOrWebsite,
        LinkedIn_Profile: Values.payload.linkedInProfileLink,
        Twitter_Url: Values.payload.twitterProfileLink,
        Discription: Values.payload.discription,
        Name: Values.payload.fullname,
        Email: Values.payload.email,
        PhoneNumber: Values.payload.PhoneNumber,
        Country: Values.payload.Country,
        State: Values.payload.State,
        City: Values.payload.City,
        Resume: Values.payload.documentForResume
      },
      {
        headers: {
          // "Accept":"multipart/form-data",
          "Content-Type": "multipart/form-data"
        }
      }
    )
    .then(
      (res) => res.data,
      Swal.fire({
        icon: "success",
        title: "Submited Successfully"
      })
    ).then(res => {
      if (res.Id) {
        sessionStorage.removeItem("postion");
        sessionStorage.removeItem("PositionId");
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: ",Please try Again , After Sometimes"
      });
    });
}

export default AddApplicantRequest;
