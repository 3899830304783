import "./Opening.css";
import axios from "axios";
import Images from "../../utilties/Image";
import "../../component/Position/Position.css";
import React, { useEffect, useState } from "react";
import PositionComponent from "../../component/Position/PositionComponent";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import HeaderText from "../../component/HeadText/Header";
import HeaderData from "../../component/HeaderData";
import ImageComponent from "../../component/Image/ImageComponent";
import { BASE_URL, PositionData } from "../../api";
import Error from "../../component/Error";

const Opening = () => {
  const [positiondata, setPositiondata] = useState([]);
  const [isError, setError] = useState(false);
  useEffect(() => {
    axios
      .get(BASE_URL + PositionData)
      .then((res) => {
        setPositiondata(res.data);
      })
      .catch((Error) => {
        setError(true);
        throw Error;
      });
  }, []);

  return (
    <>
      <ImageComponent head="Current Openings" bannerImage={Images.opening} />
      <div className="text-center">
        <div className="openingText my-5">{HeaderData.openingText1}</div>
        <h1 className="openingHead my-5">{HeaderData.openingText2}</h1>
      </div>
      <HeaderText
        heading={HeaderData.position}
        smalltext={HeaderData.positionText}
      />

      <Container className="my-2">
        <Row className="bg">
          <Col className="text-start">Role</Col>
          <Col className="text-end">Location</Col>
        </Row>
      </Container>
      <ListGroup>

        {isError ? <Error /> : null}
        {positiondata.map(({ Designation, JobLocation, Pid, Qualification }) => {
          return (
            <PositionComponent
              id={Pid}
              key={Pid}
              data={Designation}
              location={JobLocation}
              description={Qualification}
            />
          );
        })}
      </ListGroup>
    </>
  );
};

export default Opening;
