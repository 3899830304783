import { type } from "@testing-library/user-event/dist/type";
import "./Button.css";
import React from "react";
import { Container } from "react-bootstrap";

const Button = ({ buttonText, onclick, type }) => {
  return (
    <>
      <Container>
        <button type={type} className="button" onClick={onclick}>{buttonText}</button>
      </Container>
    </>
  );
};

export default Button;
