import axios from "axios";

function GetPositionRequest(Values) {
  return axios
    .get(process.env.REACT_APP_API_Position + Values.payload)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}
export default GetPositionRequest;
