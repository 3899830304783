import React from "react";
import "./LifeCard.css";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";

const LifeCard = ({ cardimg, cardheader, cardText, headcolor }) => {
  return (
    <Card className="life-card" data-aos="fade-up">
      <Row sx="auto" >
        <Col className="life-col ms-3">
          <Card.Img src={cardimg} className="mt-4 life-card-img" />
        </Col>

        <Row sx="auto">
          <Col>
            <Card.Body>
              <Card.Title className="life-card-title ms-2"
                style={{ color: headcolor }}>
                {cardheader}
              </Card.Title>
            </Card.Body>
          </Col>
        </Row>
      </Row>

      <Row sx="auto">
        <Col className="ms-4 me-1 mb-1">
          <Card.Text className="life-card-text">{cardText}</Card.Text>
        </Col>
      </Row>
    </Card>
  );
};

export default LifeCard;
