import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../RecruitmentEvolution/RecruitmentCard.css";

const RecuritmetCard = ({ cardheader, cardText }) => {
  return (
    <Card className="p-3 rectangle recruitment-card">
      <div data-aos="fade-up">
        <Row>
          <Col>
            <Card.Body>
              <Card.Title className="h4 mb-4 recruitment-card-text ">
                {cardheader}
              </Card.Title>
              <Card.Text className="recruitment-card-detail">
                {cardText}
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default RecuritmetCard;
