import React from "react";
import "./Header.css";
import { Container } from "react-bootstrap";

const HeaderText = ({ heading }) => {
  return (
    <div
      data-aos="fade-up">
      <Container>
        <p className="header m-5">{heading}</p>
      </Container>
    </div>
  );
};

export default HeaderText;
