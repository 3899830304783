import React from "react";
import "./Faq.css";
import FAQdata from "../FAQdata";
import Images from "../../utilties/Image";
import HeaderText from "../HeadText/Header";
import { Accordion, Image } from "react-bootstrap";

const Faq = () => {
  return (
    <>
      <div data-aos="fade-up">
        <div className="mt-5 faq-heading">
          <HeaderText heading={"Quick Questions & Answers"} />
        </div>

        <div className="man-faq Container my-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Image className="faq-link-img" src={Images.faqLink} />
                <p className="faq-header-text"> {FAQdata.faqheader1}</p>
              </Accordion.Header>
              <Accordion.Body className="mx-5">
                {FAQdata.faqanswer1}</Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <Image className="faq-link-img" src={Images.faqLink} />
                <p className="faq-header-text"> {FAQdata.faqheader2}</p>
              </Accordion.Header>
              <Accordion.Body className="mx-5">{FAQdata.faqanswer2}</Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <Image className="faq-link-img" src={Images.faqLink} />
                <p className="faq-header-text">{FAQdata.faqheader3}</p>
              </Accordion.Header>

              <Accordion.Body className="mx-5">{FAQdata.faqanswer3}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="contact mx-5 my-5">
            <h6>
              {" "}
              In case of more questions/concerns, write to us at
              <a href="mailto: hr@prydan.com." className="hyperlink">
                {" "}
                hr@prydan.com.
              </a>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
