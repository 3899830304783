import React from "react";
import "./Passion.css";
import Images from "../../utilties/Image";
import { Container, Image, Col, Row } from "react-bootstrap";

const Passion = () => {
  return (
    <Container>
      <Row className="passion-man-row m-5">
        <Col sm={6}
          className="passion-left-col">

          <Row
            className="pt-4 mx-auto">
            <h2
              className="passion-heading">
              Igniting Passions</h2>
          </Row>

          <Row
            className="passion-pera1 p-3">
            <p
              className="pera-content my-3"><b>
                ‘To win in the marketplace, you must first win in the workplace.’
              </b>
              <br />
              <i style={{ fontSize: "18px" }}>- Doug Conant</i>
            </p>
          </Row>

          <Row
            className="passion-pera-2 pt-3">
            <p
              className="pera-content-2">
              With a friendly, intellect driven work environment and open door
              policy, each resource is motivated to discover their true passions
              and channelize it for the betterment of their work. A happy
              inspiring resource elates us.
            </p>
          </Row>
        </Col>

        <Col sm={6}
          className="passion-left-col mt-5">

          <Image fluid className="passion-img" src={Images.img1} />
        </Col>
      </Row>
    </Container>
  );
};
export default Passion;
