import React from "react";
import HeaderData from "../HeaderData";
import Images from "../../utilties/Image";
import HeaderText from "../HeadText/Header";
import ImageComponent from "../Image/ImageComponent";
import SmallHeaderText from "../SmallHeader/SmallHeader";

const Recruitmentcomp1 = () => {
  return (
    <div>
      <ImageComponent head="Hiring Process"
        bannerImage={Images.recruitmentimg}
      />

      <HeaderText
        heading={HeaderData.Recruitment}
      />

      <div style={{ margin: "0px auto 144px", width: "970px" }}>
        <SmallHeaderText
          smalltext={HeaderData.RecruitmentText}
        />
      </div>
    </div>
  );
};

export default Recruitmentcomp1;
