const postionforResume = sessionStorage.getItem("postion")
const initialState = {
    loading : false,
    Applicant : [],
    position : postionforResume,
    error : null
}

export const AddApplicantDetails = (state = initialState , action) => {
    switch (action.type){
        case "AddApplicant_Request" :
            return {
                loading : true
            }
        case "AddApplicant_Success" :
            return {
                loading : false,
                Applicant : action.payload
            }
        case "AddApplicant_Failed" :
            return {
                loading : false,
                error : action.message
            }
        case "AddPostion_Name" :
            return {

                position : action.payload
            }
        default : 
            return state
    }
}