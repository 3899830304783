import RootSaga from "./Saga/RootSaga";
import RootReducer from "./RootReducer";
import { compose, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";

const sagaMiddleware = createSagaMiddleware();

const Store = compose(applyMiddleware(sagaMiddleware))(configureStore)({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

sagaMiddleware.run(RootSaga);

export default Store;
