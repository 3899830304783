import axios from "axios";
import Error from "../../component/Error";
import React, { useEffect, useState } from "react";
import { BASE_URL, BlogData } from "../../api";
import BlogComponent from "../../component/BlogComponent/BlogComponent";
import HeaderText from "../../component/HeadText/Header";

const Blog = () => {
  const [Blogdata, setBlogdata] = useState([]);
  const [isError, setError] = useState(false);

  useEffect(() => {
    axios
      .get(BASE_URL + BlogData)
      .then((res) => {
        setBlogdata(res.data);
      })
      .catch((err) => {
        setError(true);
        throw err;
      });
  }, []);

  return (
    <>
      <HeaderText heading={"Insights from our experience"} />
      {isError ?
        <Error />
        : null}
      {Blogdata.map((data, key) => {
        const obj1 = data[0];
        const obj2 = data[1][0];
        const content = (obj2.SubHeading) + " : " + (obj2.Description);
        return (
          <BlogComponent
            title={obj1.Heading}
            img={obj1.Image}
            content={content.slice(0, 120)}
            bid={obj1.Bid}
          />
        );
      })}
    </>
  );
};

export default Blog;
