import './Case.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { MdArrowForwardIos } from 'react-icons/md';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'

const ContentCaseCom = ({ img, logo, cid, heading, tag }) => {

    const setCid = (value) => {
        sessionStorage.setItem("cid", value);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <div>
            <Container className='case-c my-5'>
                <Card className='case-card'>
                    <Card.Img src={img} className="case-img" alt='case-img'/>
                    <Card.ImgOverlay className='my-5'>
                        <Image src={logo} className='case-logo' alt="logo"/>
                    </Card.ImgOverlay>

                    <Card className='in-card mx-auto p-2'>
                        <Card.Body className='case-body'>
                            <Row xs='auto' className='g-2'>
                                {tag.map(({ category }) => {
                                    return (
                                        <Col className='my-2 mt-3'>
                                            <Button variant="outline-info">{category} </Button>
                                        </Col>
                                    );
                                })}
                            </Row>

                            <Row>
                                <Col sm={10}>
                                    <Card.Title className='case-title mx-2 mt-1'>
                                        {heading}
                                    </Card.Title>
                                </Col>

                                <Col>
                                    <div className='case-b mt-4'>
                                        <Link to='/case-content/'>
                                            <Button variant='info' className='c-b p-2 case-b' onClick={() => { setCid(cid) }}>
                                                <MdArrowForwardIos />
                                            </Button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Card>
            </Container>
        </div>
    )
}

export default ContentCaseCom;