import './Image.css';
import React from 'react'
import { Card } from 'react-bootstrap';

const ImageComponent = ({ head, bannerImage }) => {
  return (
    <>

      <h1 className='bannerHeader'> {head}</h1>
      <Card.Img src={bannerImage} />
    </>
  )
}

export default ImageComponent;