const initialValues = {
    loading : false,
    positionDetails : [],
    error: null
}

export const GetPosition = (state = initialValues ,action) =>{
    switch (action.type){
        case "Position_Request" :
            return {
                loading : true
            }
        case "Position_Success" :
            return {
                loading : false,
                positionDetails : action.payload
            }
        case "Position_Failed" :
            return {
                loading : false,
                error : action.message
            }
        default : 
            return state
    }
}