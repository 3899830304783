const Images = {
  map: require("../assets/Image/map-.png"),
  upload: require("../assets/Image/file.png"),
  icon: require("../assets/Image/Vector.png"),
  about: require("../assets/Image/about.png"),
  mihir: require("../assets/Image/mihir.jpg"),
  blog1: require("../assets/Image/blog2.png"),
  part1: require("../assets/Image/part1.png"),
  shirt: require("../assets/Image/shirt2.jfif"),
  banner1: require("../assets/Image/banner1.png"),
  mahendra: require("../assets/Image/mahendra.jpg"),
  caseimg1: require("../assets/Image/caseimg1.png"),
  banner2: require("../assets/Image/banner2.png"),
  banner3: require("../assets/Image/banner3.png"),
  banner4: require("../assets/Image/banner4.png"),
  banner5: require("../assets/Image/banner5.png"),
  outofbox: require("../assets/Image/outofbox.png"),
  opening: require("../assets/Image/opening.png"),
  logo: require("../assets/Image/prydan-logo.svg"),
  fwimg: require("../assets/Image/Ellipse 40.png"),
  prydan: require("../assets/Image/prydan-logo.png"),
  caselogo1: require("../assets/Image/caselogo1.png"),
  fmimg: require("../assets/Image/Ellipse 40-1.png"),
  bag: require("../assets/Image/Career icon/Bag.png"),
  resume: require("../assets/Image/submitresume.png"),
  tech: require("../assets/Image/Career icon/tech.png"),
  star: require("../assets/Image/Career icon/start.png"),
  img1: require("../assets/Image/Career icon/img1.png"),
  mind: require("../assets/Image/Career icon/mind.png"),
  happy: require("../assets/Image/Career icon/Happy.png"),
  serve: require("../assets/Image/Career icon/serve.png"),
  heart: require("../assets/Image/Career icon/heart.png"),
  phone: require("../assets/Image/Career icon/Phone.png"),
  glass: require("../assets/Image/Career icon/Glass.png"),
  growth: require("../assets/Image/Career icon/Growth.png"),
  health: require("../assets/Image/Career icon/health.png"),
  humble: require("../assets/Image/Career icon/humble.png"),
  logic: require("../assets/Image/Career icon/logical.png"),
  github: require("../assets/Image/Career icon/Github.png"),
  banner: require("../assets/Image/Career icon/Banner.png"),
  global: require("../assets/Image/Career icon/Global.png"),
  people: require("../assets/Image/Career icon/people.png"),
  problem: require("../assets/Image/Career icon/problem.png"),
  twitter: require("../assets/Image/Career icon/Twitter.png"),
  faqLink: require("../assets/Image/Career icon/faq-link.png"),
  mapIcon: require("../assets/Image/Career icon/location.png"),
  facebook: require("../assets/Image/Career icon/facebook.png"),
  feedback: require("../assets/Image/Career icon/feedback.png"),
  linkedin: require("../assets/Image/Career icon/Linkedin.png"),
  careerplan: require("../assets/Image/Career icon/Career.png"),
  instagram: require("../assets/Image/Career icon/Instagram.png"),
  mentoring: require("../assets/Image/Career icon/mentoring.png"),
  commitment: require("../assets/Image/Career icon/Commitment.png"),
  recruitmentimg: require("../assets/Image/Career icon/recuitment.png"),
  recruitmentprocess4: require("../assets/Image/Career icon/recruitmentprocess4.png"),
  recruitmentprocess: require("../assets/Image/Career icon/recruitmentprocess.png"),
  recruitmentprocess2: require("../assets/Image/Career icon/recruitmentprocess2.png"),
  recruitmentprocess3: require("../assets/Image/Career icon/recruitmentprocess3.png"),
  recruitmentimgFull: require("../assets/Image/Career icon/RecruitmentFullimg.png"),

  image1: require("../assets/photos/image1.png"),
  image2: require("../assets/photos/image2.png"),
  image3: require("../assets/photos/image3.png"),
  image4: require("../assets/photos/image4.png"),
  image5: require("../assets/photos/image5.png"),
  image6: require("../assets/photos/image6.png"),
  image7: require("../assets/photos/image7.png"),
  image8: require("../assets/photos/image8.png"),
  image9: require("../assets/photos/image9.png"),
  image10: require("../assets/photos/image10.png"),
  image11: require("../assets/photos/image11.png"),
  image12: require("../assets/photos/image12.png"),
  image13: require("../assets/photos/image13.png"),
  image14: require("../assets/photos/image14.png"),
  image15: require("../assets/photos/image15.png"),
  image16: require("../assets/photos/image16.png"),
  image17: require("../assets/photos/image17.png"),
  image18: require("../assets/photos/image18.png"),
  image19: require("../assets/photos/image19.png"),
  image20: require("../assets/photos/image20.png"),
  image21: require("../assets/photos/image21.png"),
};
export default Images;
