import React from "react";
import "./MapAnimation.scss";
import Images from "../../utilties/Image";
import { Image } from "react-bootstrap"
import { IonIcon } from "react-ion-icon";

function Maps() {
  return (
    <div className="address">
      <div className="address--image">
        <Image
          fluid
          alt="image"
          src={Images.map}
          style={{ minWidth: "370px", width: "100%", height: "100%" }}
        />
      </div>

      <div className="address--info info--1 ">
        <span className="info--text">
          <p style={{ whiteSpace: "nowrap" }}>
            504,
            <br />
            Rhythm Plaza,
            <br />
            Amar jawan <br />
            Circle,
            <br />
            Nikol,
            <br />
            Ahemedabad.
          </p>
        </span>

        <div className="icon">
          <IonIcon name="location-outline"></IonIcon>
        </div>
        <div className="info--image"></div>
      </div>
      <div className="address--info info--2">
        <span className="info--text">
          <p className="">
            B-1107,
            <br />
            Titanium City
            <br /> Center,
            <br />
            Opp. Seema Hall,
            <br />
            Prahlad Nagar,
            <br />
            Ahemedabad.
          </p>
        </span>

        <div>
          <ion-icon name="add-outline"></ion-icon>
        </div>
        <div className="info--image"></div>
      </div>
      <div className="address--info info--3 ">
        <span className="info--text">
          <p style={{ whiteSpace: "nowrap" }}>
            BHIVE Workspace,
            <br />
            No.467/468
            Situated
            <br />
            on,
            Shri Krishna
            <br />
            Temple Road,
            <br />
            Indira Nagar
            <br />
            1st Stage,
            <br />
            Bengaluru,
            560038
          </p>
        </span>

        <div className="icon">
          <IonIcon name="location-outline"></IonIcon>
        </div>
        <div className="info--image"></div>
      </div>

      <div className="address--info info--4">
        <span className="info--text">
          <p style={{ whiteSpace: "nowrap" }}>
            514 - 515,
            <br />
            Gera’sImperium
            <br />
            Alpha,Rajaram
            <br />
            PatilNagar, Vitthal
            <br />
            Nagar, Kharadi,
            <br />
            Pune, Maharashtra.
          </p>
        </span>

        <div className="icon">
          <IonIcon name="location-outline"></IonIcon>
        </div>
        <div className="info--image"></div>
      </div>

      <div className="address--info info--5">
        <span className="info--text">
          <p style={{ whiteSpace: "nowrap" }}>
            371 Haliburton
            <br />
            Hts b, Stittsville,
            <br />
            ON K2S 1S3,
            <br />
            Canada.
          </p>
        </span>

        <div className="icon">
          <IonIcon name="location-outline"></IonIcon>
        </div>
        <div className="info--image"></div>
      </div>
    </div>
  );
}

export default Maps;
