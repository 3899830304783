import "./Banner.css";
import React from "react";
import Images from "../../utilties/Image";
import { Card, Container } from "react-bootstrap";

const Banner = () => {
  return (
    <>
      <Container fluid className="banner-div"
        data-aos="zoom-in">
        <Card className="banner">
          <Card.Img
            src={Images.banner} />
        </Card>
      </Container>


      <Container data-aos="fade-up">
        <div className="bannerInnerText mx-auto mt-3" >
          <p>
            Every individual working at {""}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://prydan.com/"
              className="link">
              <b><u>Prydan</u></b></a> is a source of <b> limitless </b> energy and possibilities. Join us to explore a world full of<b> <i>techno-innovation, achievements and exultant.</i></b> Unravel your true potential and ignite those
            <b className="creative"> creative sparks.</b>
          </p>
        </div>
      </Container>
    </>

  );
};

export default Banner;
