import "./Number.css";
import React from "react";
import Card from "react-bootstrap/Card";
import { Col } from "react-bootstrap";
import CountUp from "react-countup";

const Number = ({ data, detail }) => {
  return (
    <Col>
      <div className="m-3">
        <Card.Body>
          <Card.Title className="count">
            <CountUp
              end={data}
              useEasing={false}
              useGrouping={true}
              separator=","
              duration={15}
              decimal="."
            />
            +
          </Card.Title>
          <Card.Subtitle className="mb-2 detail">{detail}</Card.Subtitle>
        </Card.Body>
      </div>
    </Col>
  );
};

export default Number;
