import "./Life.css";
import React from "react";
import CardData from "../../component/CardData";
import Button from "../../component/Button/Button";
import HeaderData from "../../component/HeaderData";
import LifeCard from "../../component/LifeCard/LifeCard";
import HeaderText from "../../component/HeadText/Header";
import LifeSlider from "../../component/LifeSlider/LifeSlider";
import { Row, Col, Container, Card } from "react-bootstrap";
import SmallHeaderText from "../../component/SmallHeader/SmallHeader";
import { Link } from "react-router-dom"

const Life = () => {

  return (
    <>
      <LifeSlider />
      <div>
        <Container>
          <div>

            <h2
              className="headerLife m-5 p-5">
              We Promote a culture of Growth and Success for our people as much as our business.</h2>
          </div>

          <h6
            className="smalltext"><b>
              - Mr. Amit Kumar Trivedi <br />
            </b>
            (CEO @ Prydan)
          </h6>
        </Container>

        <Container
          className="text-cnt mt-5">
          <Card
            className="textheading"
            data-aos="fade-up">
            <h4
              className="headingsmall ms-4">
              “Doing It with Passion or Not At All” is The Way We Do It @
              Prydan!”
            </h4>
            <h2
              className="small ms-4">- Steve Jobs</h2>
          </Card>
        </Container>

        <Container className="mt-5">
          <SmallHeaderText
            smalltext={HeaderData.lifeText}
          />

          <h4 className="headingText text-center">
            An engaged and excited workforce creates a motivated effective
            organization.
          </h4>
        </Container>

        <Container>
          <HeaderText
            heading={HeaderData.cultureHeader}
          />
          <div className="mb-5">

            <SmallHeaderText
              smalltext={HeaderData.cultureText}
            />
          </div>
        </Container>
      </div>

      <Container
        className="my-4">
        <Row xs="auto"
          className="g-4 d-flex justify-content-center">
          <Col>
            <LifeCard
              headcolor={"#ff017d"}
              cardimg={CardData.cardimg10}
              cardheader={CardData.cardheader10}
              cardText={CardData.carddetail10}
            />
          </Col>

          <Col>
            <LifeCard
              headcolor={"#ffbc00"}
              cardimg={CardData.cardimg11}
              cardheader={CardData.cardheader11}
              cardText={CardData.carddetail11}
            />
          </Col>

          <Col>
            <LifeCard
              headcolor={"#00d6fb"}
              cardimg={CardData.cardimg12}
              cardheader={CardData.cardheader12}
              cardText={CardData.carddetail12}
            />
          </Col>

          <Col>
            <LifeCard
              headcolor={"#4C6CC4"}
              cardimg={CardData.cardimg13}
              cardheader={CardData.cardheader13}
              cardText={CardData.carddetai13}
            />
          </Col>
        </Row>
      </Container>

      <div className="apply"
        data-aos="fade-up">
        <Container>
          <Row className="p-5 m-5">
            <Col sm={9}>
              <h3
                className="heading">
                Interested, but can&apos;t find a right fit?
              </h3>

              <text className="join">
                Join us to explore and grow.</text>
            </Col>

            <Col
              className="pt-4" sm={3}>
              <Link to="/submit-resume/">
                <Button buttonText={"Apply Now"} />
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Life;
