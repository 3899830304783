import axios from "axios";
import "../../component/CaseComponent/Case.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { BASE_URL, CaseData } from "../../api";

const CaseContent = () => {
  let Cid = sessionStorage.getItem("cid");

  const [len, setLen] = useState();
  const [Nextdata, setNextdata] = useState();
  const [Cdata1, setCdata1] = useState([]);
  const [Cdata2, setCdata2] = useState([]);

  useEffect(() => {
    axios.get(BASE_URL + CaseData).then((res) => {
      setLen(res.data.length);
    });
    if (Nextdata === Cid) {
      setNextdata(Cid);
    } else {
      setNextdata(sessionStorage.getItem("cid"));
    }

    axios.get(BASE_URL + CaseData + "/" + Nextdata).then((res) => {
      setCdata1(res.data[0]);
      setCdata2(res.data[1]);
    });
  }, [Cid, Nextdata]);

  const Prevcase = () => {
    Cid--;
    sessionStorage.setItem("cid", Cid);
    setNextdata(Cid);
  };

  const Nextcase = () => {
    Cid++;
    sessionStorage.setItem("cid", Cid);
    setNextdata(Cid);
  };

  return (
    <>

      <h1 className="case-header my-4">{Cdata1.Heading}</h1>
      <Container fluid>
        <Image className="case-img" src={Cdata1.Image} />
      </Container>


      <Container className="case-heading p-5">
        {Cdata2.map(({ SubHeading, Description }) => {
          return (
            <div className="my-5">
              <h3 className="my-3 subHeading">{SubHeading}</h3>
              <div className="case-text mx-4">{Description}</div>
            </div>
          );
        })}
      </Container>

      <Container>
        <Row>
          <Col style={{ textAlign: "start" }}>
            <Button
              variant="info"
              onClick={Prevcase}
              disabled={Cid === 1 ? true : false}>
              Previous
            </Button>
          </Col>

          <Col style={{ textAlign: "end" }}>
            <Button
              variant="info"
              onClick={Nextcase}
              disabled={Nextdata === len ? true : false}>
              Next
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CaseContent;