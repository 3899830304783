import "./Position.css";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Collapse, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom"

const PositionComponent = ({ data, location, description, id }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const setpostion = (values) => {
    sessionStorage.setItem("postion", data);
    sessionStorage.setItem("PositionId", values);
    dispatch({ type: "AddPostion_Name", payload: data });
  };

  return (
    <div data-aos="fade-up">
      <Container className="my-2">

        <Button
          variant="flat"
          className="p-btn ms-auto"
          style={{ width: "100%" }}
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}>

          <Row className="details">
            <Col className="text-start text-bold">{data}</Col>
            <Col className="text-end">{location}</Col>
          </Row>
          
          <Collapse in={open}>
            <div id="example-collapse-text">
              <Row>
                <Col className="data-start pt-4">{description}</Col>
                <Col className="text-end">
                  <Link to="/submit-resume">
                    <Button
                      variant="flat"
                      className="apply-btn me-auto my-3"
                      onClick={() => {
                        setpostion(id);
                      }}
                    >
                      Apply Now
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </Collapse>

        </Button>
      </Container>
    </div>
  );
};

export default PositionComponent;
