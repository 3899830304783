const FAQdata = {
  faqheader1: "  What does the workweek look like at Prydan? What are the working hours like?",
  faqanswer1: "We work 5 days a week, from 10 a.m. to 7 p.m.",

  faqheader2:
    "Do you offer working from a remote option?",
  faqanswer2: "No, working from a remote location is possible under special circumstances only. All our teams work out of our Ahmedabad and Bangalore offices or are on-site deployment.",

  faqheader3: "Do you offer part time positions?",
  faqanswer3:
    "Yes, we offer both part time and full time positions.",

  faqheader4: "Do you hire professionals from the USA and India only?",
  faqanswer4:
    "No. We are a global agency, we are open to hire professionals from across the globe.",

  faqheader5: "What all employment benefits do you provide?",
  faqanswer5:
    "Our Perks & Benefits section will provide you a better understanding.",

  faqheader6:
    "To whom should I connect, if I have questions which are not covered above?",
  faqanswer6:
    "Pls write to us at hr@prydan.com, our HR team will connect back.",
};
export default FAQdata;
