import "./Nav.css";
import React from "react";
import { useState } from "react";
import Button from "../Button/Button";
import Footer from "../Footer/Footer";
import LogoCompoment from "../LogoCompoment";
import { NavLink, Outlet } from "react-router-dom";
import { Container, Navbar, NavbarBrand, Nav } from "react-bootstrap";

function NavComponent() {
  const [toggle, setToggle] = useState();

  return (

    <>
      <div className="nav-p">
        <Navbar
          className="nav-p"
          collapseOnSelect={toggle} expand="lg" variant="light"
          expanded={toggle}>

          <Container>
            <NavbarBrand className="m-1 p-1">

              <LogoCompoment />

            </NavbarBrand>

            <Navbar.Toggle
              data-toggle="collapse"
              style={{ width: "fit-content" }}
              aria-controls="responsive-navbar-nav"
              onClick={() => { toggle ? setToggle(false) : setToggle(true) }}
            />

            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end">
              <Nav onSelect={false}
                className="manlink">
                <Nav.Link
                  className="nlink" id>

                  <NavLink
                    to="/life@prydan/"
                    onClick={() => {
                      setToggle(false);
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}

                    className={({ isActive }) =>
                      (isActive ? "navlink" : "hover-underline-animation")}>
                    Life @ Prydan
                  </NavLink>
                </Nav.Link>

                <Nav.Link
                  className="nlink">
                  <NavLink
                    to="/hiring-process/"
                    onClick={() => {
                      setToggle(false);
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}

                    className={({ isActive }) =>
                      (isActive ? "navlink" : "hover-underline-animation")}>
                    Hiring Process
                  </NavLink>
                </Nav.Link>

                <Nav.Link
                  className="nlink">
                  <NavLink
                    to="/submit-resume/"
                    onClick={() => {
                      setToggle(false);
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}

                    className={({ isActive }) =>
                      (isActive ? "navlink" : "hover-underline-animation")}>
                    Submit Resume
                  </NavLink>
                </Nav.Link>

                <Nav.Link
                  className="nlink">
                  <NavLink to="/about-prydan/"
                    onClick={() => {
                      setToggle(false);
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}
                    className={({ isActive }) =>
                      (isActive ? "navlink" : "hover-underline-animation")}>
                    About Us
                  </NavLink>
                </Nav.Link>

                <NavLink
                  className="nlink"
                  to="/current-openings/"
                  onClick={() => {
                    setToggle(false);
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    })
                  }}>

                  <Button buttonText={"Current Openings"} />
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Outlet />

        <Footer />

      </div>
    </>
  );
}

export default NavComponent;
