const HeaderData = {
  banner:
    "Every individual working at Prydan is a source of limitless energy and possibilities. Join us to explore a world full of techno-innovation, achievements and exultantly. Unravel your true potential and ignite those creative sparks. ",
  benefit: "Why Prydan?",
  position: "Open Positions",
  positionText:
    "Prydan is looking for thinkers, strategist’s and digital innovators, who can help our clients, achieve the resolution; they desire and partake in the mission of digitization and technological transformation.",
  feedback: "What Our People Say?",
  feedbackText: "The most important certification ever for us!",
  lifeHeader:
    " We Promote a culture of Growth and Success for our people as much as our business.",
  lifeText:
    "In today’s dynamic environment, lifestyle, motivating factors, expectations, inspirations, aspirations- everything is changing constantly. Our workforce, comprising mainly of millennials or Gen Y, are the face of this vibrant movement. As an organization, Prydan is committed to providing an adaptable, enriching work place experience for its personnel, who are responsible for the company’s growth and success. A usual day at Prydan is full of exciting projects, fun filled conversations, in-depth discussion over coffee and much, much more.",
  cultureHeader: "Creating with Care",
  cultureText:
    "Innovation is always driven by need and desire. At Prydan, we are committed towards customer centrality blended with workforce efficacy. We work towards building simple solutions to resolve complex problems and deliver it to the client with an unpretentious smile. As an organization, Prydan values emotional intelligence and mental wellness, along with overall satisfaction quotient for its personnel. The teams are dedicated working towards creating technologically advanced platforms, in a sustainable environment.",
  Recruitment: "Recruitment Process",
  RecruitmentText:
    "Our entire hiring process adopts a methodical approach to connect with the right talent. The process not only evaluates the individual on his/her skill set, but also thoroughly assess the work ethics, emotional quotient and humane touch. Each of our team members, not only internalizes the company ethos, but outperforms and in turn creates an innovation driven, balanced workplace.",
  aboutText1:
    "Prydan Consultancy is one of ahmadabad's leading Technology consultants.  Incepts in 2016, we work with a wide variety of technological solutions- AI ML, DevOps, Embedded Software, Clouds, IoT Development, Enterprise Sales and many more.",
  aboutText2:
    "We are an organically growing company and have three offices in India. With an aptitude for business excellence, we are in constant search for fresh talent, with a myriad of skills. Owing to the volatile nature of our industry, Prydan wants to have people who are eager to learn, agile to adapt and dedicated to perform.",
  aboutText3:
    "When you join Prydan, you are not only unlocking a treasure of opportunities, but are also taking the right step towards achieving excellence. You get to work with various Fortune 500 companies and learn invaluable business lessons from them.",
  aboutText4:
    "Our management gives utmost importance to work-life balance and hence encourages de-stressing activities, hobbies and experiences. A happy resource means greater commitment and even greater results.",
  openingText1:
    "We are growing rapidly and are always on look-out for enthusiastic, diligent and talented people to join us.",
  openingText2:
    "Check out the open positions at our offices in Ahmadabad and Bangalore.",
};

export default HeaderData;
