import "./AboutUs.css";
import React from "react";
import Images from "../../utilties/Image";
import CardData from "../../component/CardData";
import { Container, CardGroup } from "react-bootstrap";
import HeaderText from "../../component/HeadText/Header";
import AboutCard from "../../component/AboutCard/AboutCard";
import ImageComponent from "../../component/Image/ImageComponent";
import SmallHeaderText from "../../component/SmallHeader/SmallHeader";

function AboutUs() {
  return (
    <>
      <div>
        <ImageComponent head={"About Us"} bannerImage={Images.about} />
      </div>
      <Container>

        <h1 className="aboutText mx-5 mt-5" data-aos="fade-up">
          Prydan Consultancy is one of Ahmadabad's<b> leading Technology </b> consultants. Incepts in <b>2016,</b> we work with a wide variety of technological solutions- AI ML, DevOps, Embedded Software, Clouds, IoT Development, Enterprise Sales and many more.
        </h1>

        <h1 className="aboutText mx-5 mt-4">
          We are an organically <b>growing company </b> and have three offices in India. With an aptitude for business excellence, we are in constant <b>search for fresh talent,</b> with a myriad of skills. Owing to the volatile nature of our industry, Prydan wants to have people who are <b> eager to learn,</b> agile to adapt and dedicated to perform.
        </h1>

        <h1 className="aboutText mx-5 mt-4" data-aos="fade-up">
          When you join Prydan, you are not only unlocking a <b> treasure of opportunities,</b> but are also taking the right step towards <b> achieving excellence.</b> You get to work with various Fortune
          <b> 500 </b>companies and learn invaluable business lessons from them.
        </h1>

        <h1 className="aboutText mx-5 mt-4">
          Our management gives utmost importance to <b> work-life balance</b> and hence encourages de-stressing activities, hobbies and experiences. A happy resource means <b>greater commitment</b> and even greater results. </h1>

        <h1 className="aboutText mx-5 mt-5" data-aos="fade-up">
          So, come, talk to us and see how you can <b>contribute </b> and <b> grow </b> with
          Prydan.
        </h1>
      </Container>

      <HeaderText
        heading="Our Core Values"
      />
      <SmallHeaderText
        smalltext="Leverage the full potential of software technologies for your business to gain edge on competition."
      />
      <Container>
        <CardGroup>
          <AboutCard
            color={"#ff017d"}
            cardimg={CardData.cardimg21}
            cardheader={CardData.cardheader21}
            cardText={CardData.carddetail21}
          />

          <AboutCard
            color={"#ffbc00"}
            cardimg={CardData.cardimg23}
            cardheader={CardData.cardheader23}
            cardText={CardData.carddetail23}
          />
        </CardGroup>

        <CardGroup>
          <AboutCard
            cardimg={CardData.cardimg8}
            cardheader={CardData.cardheader24}
            cardText={CardData.carddetail24}
            color={"#00d6fb"}
          />

          <AboutCard
            color={"#4C6CC4"}
            cardimg={CardData.cardimg22}
            cardheader={CardData.cardheader22}
            cardText={CardData.carddetail22}
          />
        </CardGroup>
      </Container>
    </>
  );
}

export default AboutUs;
