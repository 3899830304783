import React from "react";
import CardData from "../../component/CardData";
import Banner from "../../component/Banner/Banner";
import BannerCard from "../../component/Card/BannerCard";
import HeaderData from "../../component/HeaderData";
import Passion from "../../component/Passion/Passion";
import HeaderText from "../../component/HeadText/Header";
import Position from "../../component/Position/Position";
import Number from "../../component/NumberIncrement/Number";
import Numberinfo from "../../component/NumberIncrement/NumberInfo";
import HomeFeedback from "../../component/HomeFeedback/HomeFeedback";
import { CardGroup, Col, Container, Row } from "react-bootstrap";

function Home() {
  return (
    <>
      <Banner />

      <HeaderText heading={HeaderData.benefit} />
      <Container>
        <Row
          xs="auto"
          className="g-4 d-flex justify-content-center text-center my-1">
          <Col>
            <BannerCard
              cardimg={CardData.cardimg1}
              cardheader={CardData.cardheader1}
              cardText={CardData.carddetail1}
              headcolor="#ff017d"
            />
          </Col>

          <Col>
            <BannerCard
              cardimg={CardData.cardimg2}
              cardheader={CardData.cardheader2}
              cardText={CardData.carddetail2}
              headcolor="#00d6fb"
            />
          </Col>

          <Col>
            <BannerCard
              cardimg={CardData.cardimg3}
              cardheader={CardData.cardheader3}
              cardText={CardData.carddetail3}
              headcolor="#ffbc00"
            />
          </Col>
        </Row>

        <Row
          xs="auto"
          className="g-4 d-flex justify-content-center text-center my-1">
          <Col>
            <BannerCard
              cardimg={CardData.cardimg4}
              cardheader={CardData.cardheader4}
              cardText={CardData.carddetail4}
              headcolor="#ff017d"
            />
          </Col>

          <Col>
            <BannerCard
              cardimg={CardData.cardimg5}
              cardheader={CardData.cardheader5}
              cardText={CardData.carddetail5}
              headcolor="#00d6fb"
            />
          </Col>

          <Col>
            <BannerCard
              cardimg={CardData.cardimg6}
              cardheader={CardData.cardheader6}
              cardText={CardData.carddetail6}
              headcolor="#ffbc00"
            />
          </Col>
        </Row>
      </Container>

      <Passion />

      <CardGroup data-aos="fade-up">
        <Number data={Numberinfo.number1} detail={Numberinfo.detail1} />
        <Number data={Numberinfo.number2} detail={Numberinfo.detail2} />
        <Number data={Numberinfo.number3} detail={Numberinfo.detail3} />
        <Number data={Numberinfo.number4} detail={Numberinfo.detail4} />
      </CardGroup>

      <HomeFeedback />

      <Position />
    </>
  );
}

export default Home;
