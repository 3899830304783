import "./Form.css";
import "yup-phone";
import "./Resume.css";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import Images from "../../utilties/Image";
import { addUser } from "../../Redux/Action";
import React, { useEffect, useState } from "react";
import Button from "../../component/Button/Button";
import { BASE_URL, PositionData } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import ImageComponent from "../../component/Image/ImageComponent";
import { Card, Form, FormGroup, FormControl, FormLabel, Container } from "react-bootstrap";

function SubmitResume() {
  const Role = useSelector((state) => state.AddApplicantDetails.position);
  const Positionid = sessionStorage.getItem("PositionId");
  const dispatch = useDispatch();
  const [JobDiscription, setJobDiscription] = useState([]);

  const formValuesAfter = {
    City: "",
    State: "",
    email: "",
    Country: "",
    fullname: "",
    PhoneNumber: "",
    discription: "",
    joiningPeriod: "",
    TotalExperience: "",
    currentAnuualPay: "",
    documentForResume: "",
    RelevantExperience: "",
    twitterProfileLink: "",
    expectedAnuualPay: "",
    linkedInProfileLink: "",
    CurrentOrganization: "",
    personalBlogOrWebsite: "",
    positionAppliedFor: Role == null ? "" : Role,
  };
  const phoneSchema = Yup.string()
    .phone("IN")
    .required(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Phone number is required");

  useEffect(() => {
    // sessionStorage.removeItem("postion");
    // sessionStorage.removeItem("PositionId");
    if (Positionid != null) {
      axios
        .get(BASE_URL + PositionData + Positionid)
        .then((res) => {
          setJobDiscription(res.data);
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [Role, Positionid]);

  const validationSchema = Yup.object({
    fullname: Yup.string()
      .max(100)
      .required("*Full Name is required")
      .min(2, "*invalid Full Name"),

    email: Yup.string().email("*Invalid Email").required("*Email is required"),

    PhoneNumber: phoneSchema
      .required('*A phone number is required'),

    City: Yup.string().required("*City is Required").min(2, "*Invalid City"),

    Country: Yup.string()
      .required("*Country Required")
      .min(3, "*Invalid Country"),

    State: Yup.string().required("*State is Required").min(3, "*Invalid State"),

    documentForResume: Yup.string().required("*Resume is required"),

    discription: Yup.string().max(300, "Not more than 300 characters"),
  });

  const SkillSets =
    JobDiscription.Skills == null ? null : JobDiscription.Skills.split("," || "•");
  const jobResponsibilitySets = JobDiscription.JobResponsibility == null ? null : JobDiscription.JobResponsibility.split("•");

  return (
    <>
      <ImageComponent head={Role == null ? "Submit Resume" : Role}
        bannerImage={Images.resume}
      />

      <div className="mb-5 container my-5">
        {JobDiscription.length === 0 ? null : (
          <div className="text-start m-5">
            <h3 className="jobdes-title">Qualification</h3>
            <li className="jobdes-text"> {JobDiscription.Qualification}</li>
            <br />
            <br />

            <h3 className="jobdes-title">Experience</h3>
            <li className="jobdes-text">{JobDiscription.Experience}</li>
            <br />
            <br />

            <div>
              <h3 className="jobdes-title">Job Location</h3>
              <li className="jobdes-text">{JobDiscription.JobLocation}</li>
              <br />
              <br />

              <h3 className="jobdes-title">Skills</h3>
              {SkillSets &&
                SkillSets.map((skills, index) => {
                  return (
                    <li className="jobdes-text" key={index + 1}>
                      {skills}
                    </li>
                  );
                })}
            </div>
            <br />
            <br />

            <div>
              <h3 className="jobdes-title">Job Responsibility</h3>
              {jobResponsibilitySets && jobResponsibilitySets.map((job, index) => {
                return (
                  <li className="jobdes-text" key={index}>
                    {job}
                  </li>
                );
              })}
            </div>

            <br />
            <br />
          </div>
        )}
      </div>

      <Formik
        initialValues={formValuesAfter}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          dispatch(addUser(values)).then((res) => {
          })


          resetForm();
        }}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Container data-aos="zoom-in">
              <Card className="form">
                <Form
                  onSubmit={formik.handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="fullname"
                        placeholder="*Full Name"
                        className=" custom-input"
                        {...formik.getFieldProps("fullname")}
                      />
                      {formik.touched.fullname && formik.errors.fullname ? (
                        <div className="err-text m-1 text-start">
                          {formik.errors.fullname}
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="email"
                        name="email"
                        placeholder="*Email"
                        className=" custom-input"
                        {...formik.getFieldProps("email")}
                      />

                      {formik.touched.email && formik.errors.email ? (
                        <div className="err-text m-1 text-start">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>

                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="number"
                        id="number"
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        name="PhoneNumber"
                        maxLength="10"
                        placeholder="*Phone Number"
                        className="form-control custom-input"
                        {...formik.getFieldProps("PhoneNumber")}
                      />

                      {formik.touched.PhoneNumber &&
                        formik.errors.PhoneNumber ? (
                        <div className="err-text m-1 text-start">
                          {formik.errors.PhoneNumber}
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="City"
                        placeholder="*City"
                        className="form-control custom-input"
                        {...formik.getFieldProps("City")}
                      />

                      {formik.touched.City && formik.errors.City ? (
                        <div className="err-text m-1 text-start">
                          {formik.errors.City}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>

                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="State"
                        placeholder="*State"
                        className="form-control custom-input"
                        {...formik.getFieldProps("State")}
                      />

                      {formik.touched.State && formik.errors.State ? (
                        <div className="err-text m-1 text-start">
                          {formik.errors.State}
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="*Country"
                        placeholder="*Country"
                        className="form-control custom-input"
                        {...formik.getFieldProps("Country")}
                      />

                      {formik.touched.Country && formik.errors.Country ? (
                        <div className="err-text m-1 text-start">
                          {formik.errors.Country}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>

                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <FormControl
                        className="form-control custom-input"
                        type="text"
                        placeholder="Current Organization"
                        name="CurrentOrganization"
                        {...formik.getFieldProps("CurrentOrganization")}
                      />
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <FormControl
                        className="form-control custom-input"
                        type="text"
                        placeholder="Total Experience"
                        name="TotalExperience"
                        {...formik.getFieldProps("TotalExperience")}
                      />
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="RelevantExperience"
                        className="form-control custom-input"
                        placeholder="Relevant Experience"
                        {...formik.getFieldProps("RelevantExperience")}
                      />
                    </FormGroup>

                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="joiningPeriod"
                        placeholder="Joining Period"
                        className="form-control custom-input"
                        {...formik.getFieldProps("joiningPeriod")}
                      />
                    </FormGroup>
                  </div>

                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="currentAnuualPay"
                        placeholder="Current Annual Pay"
                        className="form-control custom-input"
                        {...formik.getFieldProps("currentAnuualPay")}
                      />
                    </FormGroup>

                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="expectedAnuualPay"
                        className="form-control custom-input"
                        placeholder="Expected Annual Pay"
                        {...formik.getFieldProps("expectedAnuualPay")}
                      />
                    </FormGroup>
                  </div>

                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="positionAppliedFor"
                        className="form-control custom-input"
                        placeholder="Position Applied For"
                        {...formik.getFieldProps("positionAppliedFor")}
                      />
                    </FormGroup>

                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="personalBlogOrWebsite"
                        className="form-control custom-input"
                        placeholder="Personal Blog/Website"
                        {...formik.getFieldProps("personalBlogOrWebsite")}
                      />
                    </FormGroup>
                  </div>

                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        name="linkedInProfileLink"
                        placeholder="LinkedIn Profile"
                        className="form-control custom-input"
                        {...formik.getFieldProps("linkedInProfileLink")}
                      />
                    </FormGroup>

                    <FormGroup className="col-sm-6">
                      <FormControl
                        type="text"
                        className=" custom-input"
                        placeholder="Twitter URL"
                        name="twitterProfileLink"
                        {...formik.getFieldProps("twitterProfileLink")}
                      />
                    </FormGroup>
                  </div>

                  <div className="image-upload text-start">
                    <FormLabel
                      className="uploadLabel mx-3"
                      style={{ color: "#6c757d" }}
                    >
                      {" "}
                      Upload your Resume here{" "}
                    </FormLabel>

                    <FormGroup className="image-upload">
                      <FormLabel htmlFor="documentForResume" className="">
                        <img
                          src={Images.upload}
                          alt="upload"
                          style={{ width: "32px" }}
                        ></img>
                        <input
                          type="file"
                          id="documentForResume"
                          name="documentForResume"
                          hidden
                          onChange={(event) => {
                            var file = event.target.files[0];
                            formik.setFieldValue("documentForResume", file);
                          }}
                        />

                        {formik.values.documentForResume ? (
                          <>{formik.values.documentForResume.name}</>
                        ) : (<span className="text-danger mx-3">
                          only upload *.PDF and *.doc
                        </span>
                        )}
                      </FormLabel>

                      {formik.touched.documentForResume &&
                        formik.errors.documentForResume ? (
                        <div className="err-text m-1 text-start">
                          {formik.errors.documentForResume}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>

                  <div className="m-3">
                    <FormGroup className="row">
                      <FormControl
                        className="form-control custom-input col-12"
                        style={{
                          width: "55rem",
                        }}
                        type="text"
                        name="discription"
                        {...formik.getFieldProps("discription")}
                        placeholder="Tell us, why you are the right fit for Prydan?"
                      />

                      {formik.touched.discription &&
                        formik.errors.discription ? (
                        <div className="err-text m-1 text-start">
                          {formik.errors.discription}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>

                  <div className="d-flex mx-3">
                    <FormCheckInput required className="check" />

                    <FormCheckLabel style={{ paddingLeft: "10px", fontSize: "14px" }} className="text-danger">
                      {" "}<b>*I give my consent to storage and processing of my Personal Data by Prydan as per their privacy policy compliance.</b>
                    </FormCheckLabel>
                  </div>

                  <div className="row m-3 text-center">
                    <Button buttonText={"Apply Now"} type={'submit'}></Button>
                  </div>
                </Form>
              </Card>
            </Container>
          );
        }}
      </Formik>
    </>
  );
}

export default SubmitResume;
