import "./Footer.css";
import React from "react";
import Maps from "./Maps";
import images from "../../utilties/Image";
import LogoCompoment from "../LogoCompoment";
import { TiSocialLinkedin } from "react-icons/ti";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavLink, Col, Row, Container } from "react-bootstrap";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";

function Footer() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="" data-aos="fade-up">
        <Container className="f-cnt text-start mt-5">

          <LogoCompoment />

          <Row>
            <Col>
              <br />
              <Row className="mt-5">
                <Col>
                  <b className="man mansize"> Careers</b>
                  <hr className="underline"></hr>
                  <div className="man">
                    <Link className="nlink" to="/life@prydan/" onClick={scrollToTop}>
                      <p className="hover-underline-animation">
                        Life @ Prydan
                      </p>
                    </Link>

                    <Row>
                      <Col>
                        <Link className="nlink" to="/hiring-process/" onClick={scrollToTop}>
                          <p className="hover-underline-animation">
                            Hiring Process
                          </p>
                        </Link>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Link className="nlink" to="/submit-resume/" onClick={scrollToTop}>
                          <p className="hover-underline-animation">
                            Submit Resume
                          </p>
                        </Link>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Link className="nlink" to="/current-openings/" onClick={scrollToTop}>
                          <p className="hover-underline-animation">
                            Current Openings
                          </p>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col>
                  <b className="man">Company</b>
                  <hr className="underline"></hr>
                  <div className="man">
                    <NavLink className=" nlink " href="/blog/" target="_blank" onClick={scrollToTop}>
                      <p className="hover-underline-animation">
                        Blog {"  "}
                        <sup>
                          <img src={images.icon} alt="hyperlink" />
                        </sup>
                      </p>
                    </NavLink>

                    <NavLink className="nlink " id href="/about-prydan/" target="_blank" onClick={scrollToTop}>
                      <p className="hover-underline-animation">
                        About us {" "}
                        <sup>
                          <img src={images.icon} alt="hyperlink" />
                        </sup> {" "}
                      </p>
                    </NavLink>

                    <NavLink className="nlink " href="/case-studies/" target="_blank" rel="noopener">
                      <p className="hover-underline-animation">
                        Case Studies {" "}
                        <sup>
                          <img src={images.icon} alt="hyperlink" />
                        </sup>
                      </p>
                    </NavLink>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col>
              <div className="mt-5">
                <Maps />
              </div>
            </Col>
            <Row xs="auto" className="my-3">
              <Col>
                <Row xs="auto" className="my-auto">
                  <Col className="my-1"><a className="link" href="tel: +91 8884266639"> +91 8884266639 </a>
                  </Col>
                  <Col>
                    <hr className="line"></hr>
                  </Col>
                  <Col className="my-1">
                    <a className="link" href="mailto: careers@prydan.com">career@prydan.com</a>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  {/* <Col>
                    <a href="https://github.com/githubprydan" target="_blank" rel="noopener noreferrer">
                      <FaGithub className="icon icon-g" />
                    </a>
                  </Col> */}

                  <Col>
                    <a href="https://www.facebook.com/prydanhr/" target="_blank" rel="noopener noreferrer">
                      <FaFacebook className="icon icon-f" />
                    </a>
                  </Col>

                  <Col>
                    <a href="https://www.instagram.com/prydan_consultancy_hr/" target="_blank" rel="noopener noreferrer">
                      <AiFillInstagram className="icon icon-i" />
                    </a>
                  </Col>

                  <Col>
                    <a href="https://twitter.com/Prydan_HR/" target="_blank" rel="noopener noreferrer">
                      <AiFillTwitterCircle className="icon icon-t" />
                    </a>
                  </Col>
                  <Col>
                    <a href="https://www.linkedin.com/company/prydan-software-hr/" target="_blank" rel="noopener noreferrer">
                      <TiSocialLinkedin className="con gap icon icon-li" />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>

        <div className="copy py-3">
          Copyright: &copy; {new Date().getFullYear()} Prydan.All Right
          Reserved.
        </div>
      </div>
    </>
  );
}
export default Footer;
