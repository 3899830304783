import React from 'react'
import './Error.css';
import { Card, Container } from 'react-bootstrap'

const Error = () => {

  return (
    <Container>
      <Card className="mx-5 my-2 p-3 server text-center"
        style={{
          border: "none",
          boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px"
        }}>

        <Card.Title className="my-2 oops">
          <h2>Oops!</h2>
        </Card.Title>

        <Card.Text className='my-2 data' >
          Data Not Found.Try again after sometime.
        </Card.Text>
      </Card>
    </Container>
  )
}

export default Error