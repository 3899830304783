import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, CaseData } from "../../api";
import Error from "../../component/Error";
import HeaderText from "../../component/HeadText/Header";
import CaseComponent from "../../component/CaseComponent/CaseComponent";

const Case = () => {
  const [Case, setCase] = useState([]);
  const [isError, setError] = useState(false);

  useEffect(() => {
    axios
      .get(BASE_URL + CaseData)
      .then((res) => {
        setCase(res.data);
      })
      .catch((err) => {
        setError(true);
        throw err;
      });
  }, []);

  return (
    <>
      {isError ? <Error /> : null}
      <HeaderText heading={"Case Studies"} />

      {Case.map((data, key) => {
        const obj1 = data[0];
        const obj2 = data[2];

        return (
          <div key={key}>
            <CaseComponent
              img={obj1.Image}
              logo={obj1.Logo}
              cid={obj1.Cid}
              heading={obj1.Heading}
              tag={obj2}
            />
          </div>
        );
      })}
    </>
  );
};

export default Case;
