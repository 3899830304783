import "./App.css";
import Home from "./Pages/Home/Home";
import Blog from "./Pages/Blog/Blog";
import Life from "./Pages/Life/Life";
import AboutUs from "./Pages/About/AboutUs";
import Case from "./Pages/CaseStudy/CaseStudy";
import Opening from "./Pages/Currentopening/Opening";
import BlogContentPage from "./Pages/Blog/BlogContent";
import CaseContent from "./Pages/CaseStudy/CaseContent";
import SubmitResume from "./Pages/Resume/SubmitResume";
import NavComponent from "./component/Header/NavComponent";
import Recruitment from "./Pages/Recruitment/Recruitment";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NavComponent />}>
            <Route index element={<Home />} />
            <Route path="/life@prydan/" element={<Life />} />
            <Route path="/about-prydan/" element={<AboutUs />} />
            <Route path="/current-openings/" element={<Opening />} />
            <Route path="/submit-resume/" element={<SubmitResume />} />
            <Route path="/hiring-process/" element={<Recruitment />} />
            <Route path="/case-studies/" element={<Case />} />
            <Route path="/case-content/" element={<CaseContent />} />
            <Route path="/blog/" element={<Blog />} />
            <Route path="/blog-content/" element={<BlogContentPage />} />
            {/* <Route path="/Posts" element={<Post />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;


















