import React from "react";
import HeaderText from "../HeadText/Header";
import './RecruitmentEvolution.css';

const RecruitmentEvu = () => {
  return (
    <>
      <HeaderText
        heading="The Standards of Evaluation"
      />
      <p className="requritmentSmallText">
        Each Applicant is evaluated on three criteria
      </p>
    </>
  );
};

export default RecruitmentEvu;
