import "./Blog.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, BlogData, Blogid } from "../../api";
import { Button, Col, Container, Image, Row } from "react-bootstrap";

const BlogContent = () => {
  let Id = sessionStorage.getItem("id");
  const [len, setLen] = useState();
  const [Nextid, setNextid] = useState();
  const [Bdata1, setBdata1] = useState([]);
  const [Bdata2, setBdata2] = useState([]);

  useEffect(() => {
    axios.get(BASE_URL + BlogData).then((res) => {
      setLen(res.data.length);
    });
    if (Nextid === Id) {
      setNextid(Id);
    } else {
      setNextid(sessionStorage.getItem("id"));
    }

    axios
      .get(BASE_URL + Blogid + Nextid)
      .then((res) => {
        setBdata1(res.data[0]);
        setBdata2(res.data[1]);
      })
      .catch((err) => {
        throw err;
      });
  }, [Id, Nextid]);

  const Prevblog = () => {
    Id--;
    sessionStorage.setItem("id", Id);
    setNextid(Id);
  };

  const Nextblog = () => {
    Id++;
    sessionStorage.setItem("id", Id);
    setNextid(Id);
  };

  return (
    <>
      <Container fluid>
        <Image className="blog-img" src={Bdata1.Image} />
      </Container>

      <Container className="blog-text my-4">
        <div>{Bdata1.Heading}</div>
      </Container>

      <Container className="blog-text py-2 pt-2">
        <div className="my-5">
          {Bdata2.map(({ SubHeading, Description }) => {
            return (
              <>
                <div className="my-3 blog-heading mt-5">{SubHeading}</div>
                <div className="blog-smtext">{Description}</div>
              </>
            );
          })}
        </div>
        <Container>

          <Row>
            <Col sm={1}>
              <Button
                variant="info"
                onClick={Prevblog}
                disabled={Nextid === 1 ? true : false}>
                Previous
              </Button>
            </Col>

            <Col style={{ textAlign: "end" }}>
              <Button
                variant="info"
                onClick={Nextblog}
                disabled={Nextid === len ? true : false}>
                Next
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default BlogContent;
