import { call, put, takeEvery } from 'redux-saga/effects'
import GetPositionRequest from '../request/GetPositionRequest'

function* handleGetPosition(payload) {
    try {
        const Position = yield call(GetPositionRequest, payload)
        yield put({ type: "Position_Success", payload: Position })
    } catch (err) {
        yield put({ type: "Position_Failed", message: err.message })
    }
}

function* watchGetPosition() {
    yield takeEvery("Position_Request", handleGetPosition)
}
export default watchGetPosition