import Images from "../utilties/Image";
const CardData = {
  cardimg1: Images.glass,
  cardheader1: "No Glass Door",
  carddetail1: "Interact with leadership or key stakeholders without any hierarchical baggage.",
  cardimg2: Images.mentoring,
  cardheader2: "Mentoring",
  carddetail2: "Guiding fresh minds and moulding them to harness their strengths. A personal interaction with the founder is surely exciting.",
  cardimg3: Images.happy,
  cardheader3: "Your Happy Space",
  carddetail3: "We want your workplace to be as joyful and calming as your home.",
  cardimg4: Images.growth,
  cardheader4: "Growth Aspirations",
  carddetail4: "If you have a dream goal, Prydan is here to help you attain that. Training, additional courses, real time projects- anything is possible.",
  cardimg5: Images.global,
  cardheader5: "Global exposure",
  carddetail5: "Work with the world’s top Fortune 500 companies, and learn about their best practices.",
  cardimg6: Images.phone,
  cardheader6: "Holistic Development ",
  carddetail6: " Learn what interests you. Teach what excites you. We are here to imbibe what you offer.",
  cardimg7: Images.bag,
  cardheader7: "Work With Fun",
  carddetail7: "Frequent virtual events, gathering, & continuous interaction within the team.",
  cardimg8: Images.careerplan,
  cardheader8: "Career Planning",
  carddetail8: "Career planning is a crucial step that can determine the direction of your professional life.",
  cardimg9: Images.people,
  cardheader9: "People First",
  carddetail9: "Focusing on our employee empowerment & enlightenment is our key goal.",
  cardimg10: Images.outofbox,
  cardheader10: "Being Out-Of-Box",
  carddetail10: "Thinking, creating and delivering innovative technological solutions and platforms for each of our customers, consistently.",
  cardimg11: Images.heart,
  cardheader11: "Emotional Wellness",
  carddetail11: "Being emotionally healthy is as important as physical well-being. At Prydan, a happy heart is important.",
  cardimg12: Images.humble,
  cardheader12: "Humane Touch",
  carddetail12: "Never forgetting modesty and openness while interacting with a stakeholder is a must. How you act during a crisis, personal or professional, is important.",
  cardimg13: Images.logic,
  cardheader13: "Equal Opportunity",
  carddetai13: "Prydan provides a level playing field for you to perform and excel no matter your origin, age, gender, disability or any such discriminating distinctions.",
  cardimg14: Images.heart,
  cardheader14: "Act from Heart",
  carddetail14: "Often times we, as people, go through hard times, unfortunately.Things will happen, & few decisions need to be taken, but how  you communicate & act upon it is completely upon us.",
  cardimg15: Images.mind,
  cardheader15: "Think from Mind",
  carddetail15: "Complex problems & situations need people with a mindset to see through the reality of the situation & problems. They shouldn’t sink in the emotions & other influences.",
  cardheader16: "Zeal to Perform",
  carddetail16: "How passionate you are to innovate? How committed you are to your goals? Are you ready to go that extra mile, to achieve your milestone?",
  cardheader17: "Techno-Savvy",
  carddetail17: "Business, managerial and technical skills are attractive.",
  cardimg18: Images.serve,
  cardheader18: "Serving People",
  carddetail18: "People have been the most important part of our company.The thousands of lines of code that websites, software and theapps run on are written by the people and for the people. Paying attention to people’s needs.",
  cardimg19: Images.problem,
  cardheader19: "Think from Mind",
  carddetail19: "Each problem in your life and business opens doors for new innovation & creativity. But having the right people beside you with the right skills to find & open those doors is equally important to be successful & happy.",
  cardheader20: "Integrity",
  carddetail20: "When you work with Prydan,it’s not only the salary that you get, you get  a pool of benefits that grow you as an individual.",
  cardimg21: Images.health,
  cardheader21: "Business excellence",
  carddetail21: "Pledge yourselves to always  work towards excellence.",
  cardimg22: Images.commitment,
  cardheader22: "Commitment to Smile",
  carddetail22: "To provide an energetic, vibrant and happy workplace environment.",
  cardimg23: Images.star,
  cardheader23: "Entrepreneurial",
  carddetail23: "Transform businesses adeptly and lead.",
  carddimg8: Images.careerplan,
  cardheader24: "Grow with us",
  carddetail24: "As we stride ahead, achieving our goals, run with us and achieve yours too.",
  cardheader25: "Integrity",
  carddetail25: "What are your ethical viewpoints? How humane and emotionally firm you are? Can you work, both with compassion and enthusiasm, at the same time?",
};
export default CardData;