import React from "react";
import "./RecruitmentProcess.css";
import Images from "../../utilties/Image";
import { Row, Card, Container } from "react-bootstrap";

const Recruitmentpro = () => {
  return (
    <Container>
      <Row className="process-col">
        <Card className="process-img-div mb-5">
          <Card.Img className="process-img" src={Images.recruitmentimgFull} />

        </Card>
      </Row>
    </Container>
  );
};

export default Recruitmentpro;
