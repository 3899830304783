import "./Position.css";
import axios from "axios";
import Error from "../Error";
import Button from "../Button/Button";
import HeaderData from "../HeaderData";
import Position from "./PositionComponent";
import HeaderText from "../HeadText/Header";
import React, { useState, useEffect } from "react";
import { BASE_URL, PositionData } from "../../api";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom"

const PositionPage = () => {
  const [PositionDetails, setPosition] = useState([]);
  const [isError, setError] = useState(false);

  useEffect(() => {
    axios
      .get(BASE_URL + PositionData)
      .then((res) => {
        setPosition(res.data);
      })
      .catch((err) => {
        setError(true);
        throw err;
      });

  }, []);

  return (
    <>
      <Container data-aos="fade-up">
        <HeaderText
          heading={HeaderData.position}
          smalltext={HeaderData.positionText}
        />
      </Container>

      <Container className="mt-3">
        <Row className="bg">
          <Col className="text-start mx-2">Position</Col>
          <Col className="text-end mx-3">Location</Col>
        </Row>
      </Container>

      {isError ? <Error /> : null}
      <ListGroup>
        {PositionDetails &&
          PositionDetails.slice(0, 5).map(

            ({ Designation, JobLocation, Pid, Qualification }) => {

              return (
                <Position
                  id={Pid}
                  key={Pid}
                  data={Designation}
                  location={JobLocation}
                  description={Qualification} />
              );
            }
          )}
      </ListGroup>

      <div className="p-3" data-aos="fade-up">
        <Link to="/current-openings/" className="text-center">
          <Button buttonText={"See All Openings"} />
        </Link>
      </div>
    </>
  );
};

export default PositionPage;
