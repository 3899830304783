import React from "react";
import "./AboutCard.css";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";

const AboutCard = ({ cardimg, cardheader, cardText, color }) => {
  return (
    <Card className="m-3 about-card"
      data-aos="fade-up">
      <Row sx="auto">
        <Col className="ms-3">
          <Card.Img
            src={cardimg}
            alt="card-img"
            className="mt-5 aboutbox-img" />
        </Col>
      </Row>

      <Row sx="auto">
        <Col data-aos="fade-up">
          <Card.Body>
            <Card.Title
              className="aboutbox-title"
              style={{ color: color }}>
              {cardheader}
            </Card.Title>

            <Card.Text
              className="aboutbox-text">
              {cardText}
            </Card.Text>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default AboutCard;
